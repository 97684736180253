<template>
  <div class="page__container mt-12">
    <p v-if="!submitted" class="text-center">{{ $t('onboarding.subtitleRegistration') }}</p>
    <register-creditor-stepper
      v-if="!submitted"
      :form-data.sync="formData"
      :steps="steps"
      :loading="loading"
      class="mt-9"
      @cancel="onCancel"
      @submit="onSubmit"
    />

    <success-card v-else />
  </div>
</template>

<script>
import OnboardingProductMixin from '@/mixins/OnboardingProductMixin';
import RegisterCreditorStepper from '@/shared/CreateCreditor/RegisterCreditorStepper';
import SuccessCard from '@/onboarding/components/SuccessCard';
import calculateAnnualServiceFee from '@/onboarding/helper/calculateAnnualServiceFee';
import calculateCreditorLimitFromTurnover from '@/onboarding/helper/calculateCreditorLimitFromTurnover';
import calculateFactorableAnnualTurnover from '@/onboarding/helper/calculateFactorableAnnualTurnover';
import createCreditorFactory from '@/helper/createCreditorFactory';
import { NAMESPACE } from '@/onboarding/store';
import { formatDateToTimestamp } from '@/helper/filter/formatDate';
import { mapActions } from 'vuex';

export default {
  name: 'Registration',

  mixins: [OnboardingProductMixin],

  components: {
    RegisterCreditorStepper,
    SuccessCard
  },

  data: () => ({
    formData: createCreditorFactory(),
    loading: false,
    submitted: false
  }),

  computed: {
    steps() {
      return [
        {
          step: 1,
          title: 'company',
          component: 'company-form'
        },
        {
          step: 2,
          title: 'onboarding.legalFormalities',
          component: 'legal-formalities-form'
        },
        {
          step: 3,
          title: 'onboarding.sepa',
          component: 'sepa-form'
        },
        {
          step: 4,
          title: 'onboarding.intermediary',
          component: 'intermediary-form'
        }
      ];
    },
    factorableTurnover() {
      return calculateFactorableAnnualTurnover(
        this.formData[this.fields.COMPANY][this.fields.TURNOVER],
        this.formData[this.fields.COMPANY][this.fields.ONLINE_TURNOVER_RATE]
      );
    },

    creditorLimit() {
      return calculateCreditorLimitFromTurnover(
        this.factorableTurnover,
        this.product.onboarding.maxCreditorLimitInCent
      );
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['submitRegistration', 'fetchDefaultServiceFeeMatrix']),

    async onSubmit() {
      this.loading = true;

      this.formData = this.fillBlacklistedDataWithDefaults(this.formData);

      const payload = {
        product: this.productType,
        form: {
          company: {
            name: this.formData[this.fields.COMPANY][this.fields.NAME],
            salutation: this.formData[this.fields.COMPANY][this.fields.SALUTATION],
            forename: this.formData[this.fields.COMPANY][this.fields.FIRST_NAME],
            surname: this.formData[this.fields.COMPANY][this.fields.LAST_NAME],
            tradeName: this.formData[this.fields.COMPANY][this.fields.TRADE_NAME],
            annualTurnoverCross: this.formData[this.fields.COMPANY][this.fields.TURNOVER],
            ...(!isNaN(this.formData[this.fields.COMPANY][this.fields.ONLINE_TURNOVER_RATE])
              ? {
                  onlineTurnoverRate: parseFloat(
                    this.formData[this.fields.COMPANY][this.fields.ONLINE_TURNOVER_RATE]
                  )
                }
              : {}),
            factorableAnnualTurnoverCross: this.dataNotBlacklisted(this.fields.FACTORABLE_TURNOVER)
              ? this.factorableTurnover
              : '',
            creditorLimit:
              this.dataNotBlacklisted(this.fields.CREDITOR_LIMIT) &&
              this.selectedProduct.onboarding.useCalculatedCreditorLimit
                ? this.creditorLimit
                : '' /* limit not required or set by admin in creditor-create action */,
            foundingDate: this.dataNotBlacklisted(this.fields.FOUNDING_DATE)
              ? formatDateToTimestamp(this.formData[this.fields.COMPANY][this.fields.FOUNDING_DATE])
              : '',
            street: this.formData[this.fields.COMPANY][this.fields.STREET_AND_HOUSE],
            zip: this.formData[this.fields.COMPANY][this.fields.ZIP],
            city: this.formData[this.fields.COMPANY][this.fields.CITY],
            telephoneNumber: this.formData[this.fields.COMPANY][this.fields.PHONE],
            mobileNumber: this.formData[this.fields.COMPANY][this.fields.MOBILE],
            email: this.formData[this.fields.COMPANY][this.fields.EMAIL],
            fax: this.formData[this.fields.COMPANY][this.fields.FAX]
          },
          legal: {
            entitledToDeductPreTax:
              !!this.formData[this.fields.LEGAL][this.fields.ENTITLED_TO_DEDUCT_VAT],
            ...(this.formData[this.fields.LEGAL][this.fields.VOB] !== ''
              ? {
                  vob: !!this.formData[this.fields.LEGAL][this.fields.VOB]
                }
              : {}),
            taxNumber: this.formData[this.fields.LEGAL][this.fields.TAX_NUMBER],
            authorized: '',
            registerNumber: this.formData[this.fields.LEGAL][this.fields.REGISTER_NUMBER],
            placeOfAuthority: this.formData[this.fields.LEGAL][this.fields.PLACE_OF_AUTHORITY],
            proprietor: this.formData[this.fields.LEGAL][this.fields.PROPRIETOR]
          },
          sepa: {
            name: this.formData[this.fields.SEPA][this.fields.ACCOUNT_OWNER],
            iban: this.formData[this.fields.SEPA][this.fields.IBAN]
          },
          intermediary: {
            name: this.formData[this.fields.INTERMEDIARY][this.fields.ASSET_CONSULTANT],
            salutation: this.formData[this.fields.INTERMEDIARY][this.fields.CONTACT_SALUTATION],
            forename: this.formData[this.fields.INTERMEDIARY][this.fields.CONTACT_FIRST_NAME],
            surname: this.formData[this.fields.INTERMEDIARY][this.fields.CONTACT_LAST_NAME],
            street:
              this.formData[this.fields.INTERMEDIARY][this.fields.INTERMEDIARY_STREET_AND_HOUSE],
            zip: this.formData[this.fields.INTERMEDIARY][this.fields.INTERMEDIARY_ZIP],
            city: this.formData[this.fields.INTERMEDIARY][this.fields.INTERMEDIARY_CITY],
            telephoneNumber:
              this.formData[this.fields.INTERMEDIARY][this.fields.INTERMEDIARY_PHONE],
            email: this.formData[this.fields.INTERMEDIARY][this.fields.INTERMEDIARY_EMAIL],
            department: this.formData[this.fields.INTERMEDIARY][this.fields.DEPARTMENT],
            intermediaryNumber:
              this.formData[this.fields.INTERMEDIARY][this.fields.INTERMEDIARY_NUMBER],
            salesAdvisorNumber:
              this.formData[this.fields.INTERMEDIARY][this.fields.ASSET_CONSULTANT_NUMBER],
            privacyPolicyAccepted:
              this.formData[this.fields.INTERMEDIARY][this.fields.PRIVACY_POLICY_ACCEPTED]
          },
          creditorServiceFee: {
            serviceFeeAnnual: this.isFactoring
              ? calculateAnnualServiceFee(
                  this.factorableTurnover,
                  this.formData[this.fields.CONTRACT].serviceFee
                )
              : ''
          },
          notes: this.formData[this.fields.INTERMEDIARY][this.fields.NOTES]
        }
      };

      const { error } = await this.submitRegistration(payload);
      this.loading = false;
      if (error) {
        return;
      }

      this.submitted = true;
    },

    onCancel() {
      this.$router.push({ name: this.routeName.ONBOARDING });
    }
  },

  watch: {
    '$route.query.token': {
      immediate: true,
      async handler(token) {
        if (!token) return;

        const { data } = await this.$http.get(`/sso/read`, { params: { token } });

        if (data.error) return;

        this.formData[this.fields.COMPANY] = {
          ...this.formData[this.fields.COMPANY],
          [this.fields.NAME]: data.creditor.name,
          [this.fields.FIRST_NAME]: data.creditor.contact_person.forename,
          [this.fields.LAST_NAME]: data.creditor.contact_person.surname,
          [this.fields.STREET_AND_HOUSE]:
            `${data.creditor.address.street} ${data.creditor.address.house}`.trim(),
          [this.fields.CITY]: data.creditor.address.city,
          [this.fields.ZIP]: data.creditor.address.zip,
          [this.fields.MOBILE]: data.creditor.mobile,
          [this.fields.PHONE]: data.creditor.telephone,
          [this.fields.EMAIL]: data.creditor.email
        };

        this.formData[this.fields.INTERMEDIARY] = {
          ...this.formData[this.fields.INTERMEDIARY],
          [this.fields.CONTACT_FIRST_NAME]: data.consultant.forename,
          [this.fields.CONTACT_LAST_NAME]: data.consultant.surname,
          [this.fields.INTERMEDIARY_STREET_AND_HOUSE]: data.consultant.address.street,
          [this.fields.INTERMEDIARY_ZIP]: data.consultant.address.zip,
          [this.fields.INTERMEDIARY_CITY]: data.consultant.address.city,
          [this.fields.INTERMEDIARY_PHONE]: data.consultant.telephone,
          [this.fields.INTERMEDIARY_EMAIL]: data.consultant.email
        };

        this.formData[this.fields.LEGAL] = {
          ...this.formData[this.fields.LEGAL],
          [this.fields.TAX_NUMBER]: data.creditor.tax.tax_id
        };
      }
    }
  },

  async created() {
    if (!this.isFactoring) {
      return;
    }

    const { data } = await this.fetchDefaultServiceFeeMatrix(this.productType);
    if (!data) {
      return;
    }

    this.formData[this.fields.CONTRACT].serviceFee = data.purchasedFee;
  }
};
</script>
